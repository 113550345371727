@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.editor-container {
    display: flex;
    justify-content: space-between;
    align-content: center;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 0.4rem;
    box-sizing: border-box;
    &--room-text-area {
        margin-left: 2rem;
        @media screen and (max-width: $breakpoint-1350) {
            margin-left: 0.5rem;
        }
    }

    &--event-comment-text-area {
        width: 84%;
    }

    &--user-dashboard {
        width: 84%;
    }

    &--edit-content {
        width: 100%;
    }

    &--edit-room-description,
    &--event-description {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.hidden {
    display: none !important;
}

.toolbar-hidden {
    max-height: 4rem;
}

.mce-content-body {
    width: 100%;
    min-height: 5rem;
}

.editor-container > .tox-tinymce {
    border: none !important;
    border-radius: 0 !important;
}

.editor-container--edit-room-description > .tox-tinymce {
    border-radius: 0.5rem !important;
}

.editor-container--event-description > .tox-tinymce {
    border-radius: 0.5rem !important;
}

.editor-container > .tox > .tox-editor-container > .tox-editor-header {
    border: none !important;
    box-shadow: none;
    padding: 0;
}

.editor-container > .tox :not(svg):not(rect) {
    cursor: text;
    font-family: $font-family-inter;
    font-weight: 500;
}

.editor-input {
    &__at {
        position: relative;
    }

    &__at-dropdown-edit-content,
    &__at-dropdown-room-text-area,
    &__at-dropdown-edit-room-description,
    &__at-dropdown-event-description {
        @include at_dropdown();
        width: 24.841rem;
        height: 11.549rem;
        z-index: 10;

        @media screen and (max-width: $breakpoint-1350) {
            width: 20.841rem;
        }
    }

    &__at-dropdown-event-comment-text-area {
        @include at_dropdown();
        width: 20.841rem;
        height: 11.549rem;
        z-index: 10;
    }

    &__at-dropdown-user-dashboard {
        @include at_dropdown();
        width: 20.841rem;
        height: 11.549rem;
        z-index: 10;
    }

    &__at-dropdown {
        width: 100%;
        height: 9.549rem;
        overflow: scroll;
        overflow-x: hidden;
        z-index: 10;
    }
}
::-webkit-scrollbar {
    width: 0.375rem;
}
::-webkit-scrollbar-track {
    background: #e5e5e5;
}
::-webkit-scrollbar-thumb {
    height: 4.1rem;
    background: $color-mid-dark-grey;
}

.mention-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 4.1rem;
    background-color: white;

    &:hover {
        background-color: $color-grey;
    }

    cursor: pointer;

    &__left-panel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-width: 6rem;
        max-width: 6rem;
        padding-left: 2.25rem;
        box-sizing: border-box;

        @media screen and (max-width: $breakpoint-1350) {
            padding-left: 1.25rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            min-width: 5rem;
            max-width: 5rem;
        }
    }

    &__middle-panel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 80%;
    }

    &__text-container {
        max-width: 8.5rem;

        @media screen and (min-width: $breakpoint-350) {
            max-width: 10.5rem;
        }
        @media screen and (min-width: $breakpoint-389) {
            max-width: 12.5rem;
        }
        @media screen and (min-width: $breakpoint-420) {
            max-width: 14rem;
        }
        @media screen and (min-width: $breakpoint-550) {
            max-width: 21rem;
        }
        @media screen and (min-width: $breakpoint-1199) {
            max-width: 26rem;
        }
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-darker-blue;
        word-break: break-word;
    }

    &__subtext {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        @media screen and (max-width: $breakpoint-350) {
            display: none;
        }
    }
    &__right-panel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-width: 4rem;
    }
}

.tox-button {
    background: #e09f53 !important;
    border: 0px !important;
}

.tox-button--secondary {
    background: #f0f0f0 !important;
}

.tox-button--icon {
    background:none !important;
}