.media-library {
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &__tabs-panel {
        overflow: visible !important;
    }

    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    &__content {
        display: flex;
        flex-direction: row;
        height: 100%;
        position: relative;
    }

    &__image-preview-container {
        display: flex;
        justify-content: center;
        width: 100%;
        
        img {
            max-width: 50%;
            max-height: 10rem;
        }
    }

    &__file-preview-info {
        margin-top: 1rem;
        border-top: 1px solid #ccc;
    }

    &__file-preview-button-container {
        display: flex;

        justify-content: center;
        width: 100%;
    }

    &__file-preview-button {
        margin: 0.5rem;
    }

    &__file-browser {
        display: flex;
        overflow: auto;
        height: calc(65vh - 10.9rem);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        flex-grow: 1;
    }

    &__preview-panel__header {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    &__preview-panel {
        font-family: inherit;
        display: flex;
        flex-direction: column;
        width: 20rem;
        height: calc(65vh - 10.9rem);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        @media screen and (max-width: $breakpoint-768) {
            width: 100%;
            height: unset;
        }
    }

    &__close-icon {
        width: 100% !important;
        @include icon();
    }

    &__preview-panel-content {
        font-family: 'Inter';
        font-size: $font12;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 31rem);
        overflow-y: auto;
        padding: 0.75rem;

        @media screen and (max-width: $breakpoint-768) {
            height: unset;
        }

        h3 {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__copy-link {
        font-family: 'Inter';
        font-size: $font12;
        color: $color-red;
        font-weight: 700;
    }

    &__file-preview-tags {
        margin-bottom: 0.75rem;

        p {
            margin: 0.25rem;
            margin-bottom: 1rem !important;
        }
    }

    &__file-browser-table-col-hide-mobile {
        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__file-browser-preview-active {
        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__file-browser-table {
        font-family: 'Inter';
        font-size: $font12;
        width: 100%;
        border-collapse: collapse;
        white-space: nowrap;
        th,
        td {
            padding: 0.75rem;
            text-align: left;
            border-bottom: 1px solid #ccc;
            text-overflow: ellipsis;
        }

        td {
            cursor: pointer;
        }

        th {
            background-color: $color-grey;
            font-weight: 600;
        }

        tr:nth-child(even) {
            background-color: #f9f9f9;
        }

        tr:hover {
            background-color: $color-grey;
        }
    }
}

.media-library-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter';
    padding: 0.75rem;
    border-bottom: 1px solid #ccc;

    &__checkbox-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__checkboxes {
        display: flex;

        @media screen and (max-width: $breakpoint-768) {
            flex-direction: column;
        }
    }

    &__file-counter {
        @media screen and (max-width: $breakpoint-1024) {
            display: none;
        }

        margin-left: auto !important;
        font-family: inherit;
        font-size: $font12;
    }

    &__upload-button-mobile {
        height: 2rem;
        @media screen and (min-width: $breakpoint-768) {
            display: none;
        }
    }

    &__upload-button-desktop {
        height: 2rem;
        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__search-input {
        width: 12rem;
        svg {
            padding-right: 1rem;
        }
    }

}

.media-library-header__actions {
    display: flex;
    align-items: center;
    flex-grow: 1;

    div {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }
}

.room-upload-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40rem;
    height: 52rem;
    min-width: 20rem;
    background-color: none;
    border-radius: $border-radius-09;
    box-shadow: $box-shadow-overlay;
    font-family: $font-family-inter;

    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
        height: 100vh;
        border-radius: 0;
        box-shadow: none;
    }

    &__error-text {
        color: $color-red;
        margin-left: 1rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 400;
        margin-bottom: 1rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-right: 1rem;
        }
    }

    &__checkbox-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 1rem;
    }

    &__file-upload-label {
        font-family: 'Inter';
        font-size: $font14;
        color: $color-darker-blue;
    }

    &__delete-file-button {
        margin-top: 0.25rem;
    }

    &__button-container {
        width: 100%;
        height: 2.25rem;
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        margin-top: 1rem;
    }

    &__button {
        width: 7.5rem;
        height: 2.3rem;
    }

    &__file-upload {
        border: 2px dashed #ccc;
        padding: 20px;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;
        background-color: #f9f9f9;
        font: 1em sans-serif;
    }

    &__content {
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__text-area {
        width: 100%;
    }

    &__about-container {
        display: flex;
        flex-direction: column;
    }

    &__middle-panel {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
        height: 60%;
        width: 80%;
    }

    &__max-character-container {
        display: flex;
        flex-direction: column;
        align-items: end;
        width: 100%;

        @media screen and (max-width: $breakpoint-550) {
            width: fit-content;
            padding-right: 1rem;
        }
    }

    &__upper-panel {
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        text-align: center;
    }

    &__title {
        font-family: $font-family-inter;
        font-size: $font18;
        font-weight: 700;
        color: $color-light-blue;
    }

    &__close-icon {
        position: absolute;
        right: -1rem;
        top: -0.3rem;
        @include icon;

        @media screen and (max-width: $breakpoint-768) {
            right: -1.5rem;
            top: -2rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            right: -1rem;
            top: -1.5rem;
        }
    }

    &__divider {
        height: 0.03rem;
        width: 80%;
        background-color: $color-grey-c4;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font18;
        font-weight: 500;
        color: $color-darker-blue;
        text-align: center;
    }
}